import React, { useEffect } from "react"
import Root from "../components/root"
import classes from "./success.module.css"
import CancelCross from "../assets/svg/times-circle-solid.svg"
import { Link } from "gatsby"

const Cancel = () => {
  // useEffect(() => {
  //   setTimeout(() => {
  //     navigate("/video-editing")
  //   }, 2000)
  // }, [])

  return (
    <>
      <Link to="/video-editing" className="pointer ml-2">
        Back
      </Link>
      <Root className={classes.root}>
        <CancelCross className={classes.cancel} />
        <div className="mt-8 text-xl md:text-2xl font-semibold">
          Payment Cancelled!
        </div>
      </Root>
    </>
  )
}

export default Cancel
